import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle} from "@angular/material/dialog";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-product-form',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatFormField,
    MatInput,
    ReactiveFormsModule,
    MatButton,
    MatLabel
  ],
  templateUrl: './product-form.component.html',
  styleUrl: './product-form.component.scss'
})
export class ProductFormComponent {
  protected form: FormGroup;
  constructor(@Inject(MAT_DIALOG_DATA) protected data: any, private dialogRef: MatDialogRef<ProductFormComponent>) {
    this.form = new FormGroup({
      reference: new FormControl(this?.data?.product?.reference ?? "", [Validators.required]),
      eco_tax: new FormControl(this?.data?.product?.eco_tax ?? 0),
      sorecop: new FormControl(this?.data?.product?.sorecop ?? 0)
    })
  }

  protected sumbit() {
    this.dialogRef.close(this.form.value);
  }
}
