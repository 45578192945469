<div class="p-4 w-[40vw]">
  <form [formGroup]="form" class="flex flex-col">
    <h2 matDialogTitle>{{data?.product ? "Modifier" : "Créer"}} un produit</h2>
    <mat-form-field appearance="outline">
      <mat-label>Référence interne</mat-label>
      <input type="text" matInput formControlName="reference"/>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Ecotaxe</mat-label>
      <input type="text" matInput formControlName="eco_tax"/>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Sorecop</mat-label>
      <input type="text" matInput formControlName="sorecop"/>
    </mat-form-field>
    <button mat-flat-button color="primary" [disabled]="form.invalid" (click)="sumbit()">{{data?.product ? "Modifier" : "Créer"}}</button>
  </form>
</div>
