import { Injectable } from '@angular/core';
import {HttpClient, HttpEventType, HttpHeaders, HttpRequest, HttpResponse} from "@angular/common/http";
import {map} from "rxjs";
import {User} from "../models/User";
import {environment} from "../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  url = environment.apiAddress;
  constructor(protected httpClient: HttpClient) {
  }

  get(endpoint: string, item: string = "", options = {}) {
    return this.httpClient.get(this.url + endpoint + (item ? "/" + item : ""), options);
  }

  post(endpoint: string, body: any, options = {}) {
    return this.httpClient.post(this.url + endpoint, body, options);
  }

  put(endpoint: string, item: string = "", body: any, options = {}) {
    return this.httpClient.put(this.url + endpoint + (item ? "/" + item : ""), body, options);
  }

  delete(endpoint: string, item: string = "", options = {}) {
    return this.httpClient.delete(this.url + endpoint + (item ? "/" + item : ""), options);
  }


  /*
    Fonction qui permet l'envoi de fichier
   */
  upload(endpoint: string, formData : any){
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    // headers.append('Content-Type', 'multipart/form-data'); //ne pas le mettre !
    // ...sinon cela empeche Angular de le generer tout seul au bon format soit par exemple :
    // content-type: multipart/form-data; boundary=----WebKitFormBoundaryUs31X73pZEPeHstw
    return this.httpClient.post<any>(this.url + endpoint, formData, {
      reportProgress: true,
      observe: 'events',
      headers: headers,
    });
  }


  /*
    Fonction qui permet de declencher le telechargement d'un fichier depuis le front Angular
    (utile par exemple pour des données généré dans le front
    ...ou quand l'acces à une resource du back necessite l'utilisation d'un header Authorisation donc non ouvrable dans un nouvel onglet)
    @param blob
    @param filename
  */
  public downloadBlob = (function () {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    return function (blob : any, filename : string) {
      if(!filename){
        filename = "download";
      }
      const url = URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      URL.revokeObjectURL(url);
    };
  })();





}
