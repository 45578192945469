import {Component, ElementRef, Inject, OnDestroy, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle} from "@angular/material/dialog";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {catchError, map, of, Subject, takeUntil} from "rxjs";
import {ProductService} from "../../../../../services/product.service";
import {Event, NavigationError, RouterStateSnapshot} from "@angular/router";
import { MatFormFieldModule } from "@angular/material/form-field";
import {AlertService} from "../../../../../services/alert.service";
import {ApiService} from "../../../../../services/api.service";
import {HttpErrorResponse, HttpEventType} from "@angular/common/http";
import {MatCard, MatCardActions, MatCardContent} from "@angular/material/card";
import {MatProgressBar} from "@angular/material/progress-bar";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-product-reference-mapping-form',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatFormField,
    MatInput,
    ReactiveFormsModule,
    MatButton,
    MatLabel,
    MatIconButton,
    MatIcon,
    MatFormFieldModule,
    MatCard,
    MatCardContent,
    MatProgressBar,
    MatCardActions,
    NgIf
  ],
  templateUrl: './import-product-reference-mapping-form.component.html',
  styleUrl: './import-product-reference-mapping-form.component.scss'
})
export class ImportProductReferenceMappingFormComponent implements OnDestroy {

  private unsubscribeAll: Subject<any> = new Subject();

  @ViewChild("uploadFileInput", {static: false}) uploadFileInput!: ElementRef;
  uploadFileArray: any[] = [];
  uploadFileProgressBarValue = 0;
  uploadFileProgressBarMode: "indeterminate"|"determinate" = "indeterminate";
  uploadFileResultMessageText: string = "";
  uploadFileResultMessageColor: string = "";

  constructor(@Inject(MAT_DIALOG_DATA) protected data: any, private dialogRef: MatDialogRef<ImportProductReferenceMappingFormComponent>, private productService: ProductService, private alertService: AlertService, private apiService : ApiService ) {

  }

  uploadFile(file : any, endpoint='', inputName? : string, onResponseCallBackFunction? : any) {
    if(!inputName){
      inputName = 'file'
    }
    const formData = new FormData();
    formData.append(inputName, file.data);
    file.inProgress = true;

    this.apiService.upload(endpoint, formData).pipe(
      map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              //(Chrome version 127 pass here only when upload 100% ...)
              if(event.total){
                this.uploadFileProgressBarValue = Math.round(event.loaded * 100 / event.total);
                //console.log("update this.uploadFileProgressBarValue : " + this.uploadFileProgressBarValue);
              }
              return null;
            case HttpEventType.Response:
              return event;
            default :
              return null;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        file.inProgress = false;
        if(typeof onResponseCallBackFunction === 'function'){
          onResponseCallBackFunction(false, `${file.data.name} upload failed.`, this);
        }
        return of(`${file.data.name} upload failed.`);
        //exemple : data export-product (11).csv upload failed.
        //si le back réponds :
        // <br/>
        // <b>Fatal error</b>
        // :  Maximum execution time of 30 seconds exceeded in <b>/var/www/html/vendor/doctrine/orm/src/UnitOfWork.php</b>
        // on line <b>676</b>
      }))
      .subscribe({
        next: (res: any) => {
          if (res !== null) {
            if (res.body.message === "success") {
              if(typeof onResponseCallBackFunction === 'function'){
                onResponseCallBackFunction(true, `${file.data.name} upload success.`, this);
              }
            } else {
              if(typeof onResponseCallBackFunction === 'function'){
                onResponseCallBackFunction(false, `${file.data.name} upload failed. ` + res.body.message, this);
              }
            }
          }
        }
      });

  }

  private uploadFiles(endpoint : string, onResponseCallBackFunction:any=null) {
    let inputName = this.uploadFileInput.nativeElement.name;
    this.uploadFileInput.nativeElement.value = '';
    this.uploadFileArray.forEach(file => {
      this.uploadFile(file, endpoint, inputName, onResponseCallBackFunction);
    });
  }


  ngOnDestroy(): void {
  }

  onResponseFileUpload(success:boolean, msg:string, self:any){
    self.uploadFileProgressBarMode = "determinate";  //err cannot set property of undefined
    self.uploadFileResultMessageText = msg;
    self.uploadFileResultMessageColor = success?"green":"red";
  }

  onClickFileUpload(endpoint : string, onResponseCallBackFunction:any=null) {
    const input = this.uploadFileInput.nativeElement;
    input.onchange = () => {
      for ( let index = 0; index < input.files.length; index++)
      {
        const file = input.files[index];
        this.uploadFileArray.push({ data: file, inProgress: false, progress: 0});
      }
      this.uploadFiles(endpoint, this.onResponseFileUpload);
    };
    input.click();
  }




}

