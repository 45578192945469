<h2 class="text-3xl uppercase text-blue-900 my-4 pb-1 font-bold relative">
  <div class="border-b-red-700 border-l-red-700 h-full w-10 border-l-[.3rem] border-b-[.3rem] absolute"></div>
  <span class="ml-[0.6rem]">Liste des produits</span>
</h2>
<div class="flex justify-end">
  <button mat-flat-button color="primary" (click)="addProduct()"><mat-icon>add_shopping_cart</mat-icon> Ajouter un produit</button>
</div>
<br>
<mat-form-field class="w-full">
  <mat-label>Rechercher une référence...</mat-label>
  <input matInput placeholder="..." [formControl]="searchCtl">
</mat-form-field>
<table mat-table matSort [dataSource]="productsDataSource">
  <ng-container matColumnDef="id">
    <th mat-header-cell mat-sort-header *matHeaderCellDef class="w-1/12">ID</th>
    <td mat-cell *matCellDef="let product"  class="w-1/12"> {{ product.id }}</td>
  </ng-container>
  <ng-container matColumnDef="reference">
    <th mat-header-cell mat-sort-header *matHeaderCellDef class="w-1/6">Référence interne</th>
    <td mat-cell *matCellDef="let product"  class="w-1/6"> {{ product.reference }}</td>
  </ng-container>
  <ng-container matColumnDef="references_externes">
    <th mat-header-cell *matHeaderCellDef class="w-1/6">Références externes</th>
    <td mat-cell *matCellDef="let product"  class="w-1/6">
      @if(product.references_mapping && product.references_mapping.length != 0) {
        @for(refext of product.references_mapping; track refext) {
          <mat-chip-row class="mx-1">{{refext.origin}} : {{refext.external_reference}}</mat-chip-row>
        }
      }
      @else {
        <mat-chip-row class="mx-1 bg-orange-300">Aucun mapping</mat-chip-row>
      }
    </td>
  </ng-container>
  <ng-container matColumnDef="stock">
    <th mat-header-cell mat-sort-header *matHeaderCellDef class="w-1/6">Stock</th><!-- disabled pour ne pas proposer trie -->
    <td mat-cell *matCellDef="let product"  class="w-1/6">
      @if(product.stock_warehouses && product.stock_warehouses.length != 0) {
          @for(stock of product.stock_warehouses; track stock) {
            <mat-chip-row class="mx-1 {{stock.quantity <= 0 ? 'bg-red-300' : ''}}">{{stock.warehouse.code}} : {{stock.quantity}}</mat-chip-row>
          }
      }
      @else {
        <mat-chip-row class="mx-1 bg-orange-300">Aucun stock</mat-chip-row>
      }
    </td>
  </ng-container>
  <ng-container matColumnDef="eco_tax">
    <th mat-header-cell mat-sort-header *matHeaderCellDef class="w-1/6">Ecotaxe</th>
    <td mat-cell *matCellDef="let product"  class="w-1/6"> {{ product.eco_tax }}</td>
  </ng-container>
  <ng-container matColumnDef="sorecop">
    <th mat-header-cell mat-sort-header *matHeaderCellDef class="w-1/6">Sorecop</th>
    <td mat-cell *matCellDef="let product"  class="w-1/6"> {{ product.sorecop }}</td>
  </ng-container>

  <ng-container matColumnDef="loader">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let product">
      <div class="flex p-4 h-[50vh] items-center justify-center">
        <mat-spinner></mat-spinner>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell class="w-1/6" *matHeaderCellDef></th>
    <td mat-cell class="w-1/6" *matCellDef="let product">
      <div></div>
      <div class="flex gap-2">
        <button mat-mini-fab color="primary" (click)="editProduct(product)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-mini-fab color="warn" (click)="deleteProduct(product.id)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<br>
<div class="flex justify-end gap-2">
  <button mat-flat-button color="primary" [matMenuTriggerFor]="menuExport">
    <mat-icon>cloud_download</mat-icon> Exporter
  </button>
  <mat-menu #menuExport="matMenu">
    <div>
      <button mat-menu-item (click)="exportProduct()">
        <mat-icon>description</mat-icon>
        <span>Données produits</span>
      </button>
    </div>
    <div>
      <button mat-menu-item (click)="exportProductMapping()">
        <mat-icon>compare_arrows</mat-icon>
        <span>Mapping references</span>
      </button>
    </div>
  </mat-menu>

  <button mat-flat-button color="primary" [matMenuTriggerFor]="menuImport">
    <mat-icon>cloud_upload</mat-icon> Importer
  </button>
  <mat-menu #menuImport="matMenu">
    <div>
      <button mat-menu-item (click)="importProduct()">
        <mat-icon>description</mat-icon>
        <span>Données produits</span>
      </button>
    </div>
    <div>
      <button mat-menu-item (click)="importProductMapping()">
        <mat-icon>compare_arrows</mat-icon>
        <span>Mapping references</span>
      </button>
    </div>
  </mat-menu>
</div>

<mat-paginator
  length="totalData"
  [pageIndex]="gridDefaultPageIndex"
  [pageSize]="gridDefaultPageSize"
  [pageSizeOptions]="gridPageSizeArray">
  showFirstLastButtons
></mat-paginator>
